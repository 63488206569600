import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';


import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const mapEmpty = row => {
    return Object.assign(...Object.entries(row).map(([key, value]) => {
        if (value === null) {
            return {[key]: "-"}
        }
        return {[key]: value}
    }))
}

const SessionSearchTable = props => {
    const { classes } = props;

    return (
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Eesnimi</TableCell>
                        <TableCell>Perenimi</TableCell>
                        <TableCell>Kommentaarid</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.sessions.map(row => {
                        const n = mapEmpty(row)
                        return (
                                <TableRow 
                                    hover
                                    onClick={e => navigate("/victim/" + n.kliendi_nr + "/" + n.incident_id + "/" + n.id)}
                                    key={n.id}
                                >
                                    <TableCell component="th" scope="row">{n.id}</TableCell>
                                    <TableCell>{n.first_name}</TableCell>
                                    <TableCell>{n.last_name}</TableCell>
                                    <TableCell><div style={{width: '100%'}}>{n.kommentaarid}</div></TableCell>
                                </TableRow>
                            
                        );
                    })}
                </TableBody>
            </Table>
    );
};

SessionSearchTable.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default SessionSearchTable;