import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@reach/router';
import Grid from '@material-ui/core/Grid';

import { withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import withRoot from '../withRoot';

import Layout from '../components/Layout';
import VictimTable from '../components/VictimTable';
import IncidentSearchTable from '../components/IncidentSearchTable';
import SessionSearchTable from '../components/SessionSearchTable';


const styles = theme => ({
  root: {
    textAlign: 'center',
    paddingTop: theme.spacing.unit * 20,
  },
  paper: {
    margin: theme.spacing.unit * 4,
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
      .spacing.unit * 3}px`,
  },
  input: {
    margin: theme.spacing.unit,
    minWidth: theme.spacing.unit * 20,
  },
});

class Overview extends React.Component {
  constructor(props) {
    super(props);
    this.axios = this.props.axios;
  }

  static propTypes = {
    classes: PropTypes.object.isRequired,
  };

  handleChange = event => {
    event.persist();
    this.setState((state) => ({...state, query: event.target.value}));
  };

  search = (query) => {
    this.axios.get('search.php', {params: {query}})
      .then(res => {
        console.log('res', res);
        this.setState({ results: res.data });
      })
      .catch(err => {
        setTimeout(() => this.setState({ error: '' }), 6000);
        this.setState({ results: {clients: [], incidents: [], sessions: []} });
      });
  };

  state = {
    query: '',
    results: {clients: [], incidents: [], sessions: []},
    error: '',
  };

  componentDidMount() {
    
  }

  handleSearch = event => {
    event.preventDefault();
    this.search(this.state.query);
  };

  render() {
    const { classes } = this.props;

    return (
      <Layout title="Ülevaade" error={this.state.error}>
        <Paper className={classes.paper}>
          <form onSubmit={this.handleSearch} style={{display: 'flex', width: '100%'}}>
            <TextField 
              type="text"
              id="query"
              label="Üldotsing"
              className={classes.input}
              style={{flex: '1 0 auto'}}
              value={this.state.query}
              onChange={this.handleChange}
              margin="normal"
            />
            <Button
              type="submit"
              variant="outlined"
              color="primary"
              className={classes.input}
              style={{flex: '0 0 auto', paddingTop: '0', paddingBottom: '0'}}
            >
              Otsi
            </Button>
          </form>
        </Paper>
        <Paper className={classes.paper}>
          <Grid container
            direction="column"
            justify="center"
            alignItems="center"
            spacing={8}>
            {(this.state.results.clients.length > 0 && <Grid item><h2>Isikud</h2></Grid>)}
            {(this.state.results.clients.length > 0 && <Grid item style={{width: '100%'}}><VictimTable classes={classes} victims={this.state.results.clients} /></Grid>)}
            <Grid item>
              <Link to={'victim/new/'}>
                <Button
                  variant="contained"
                  color="primary"
                >
                  Uus isik
                </Button>
              </Link>
            </Grid>
            {(this.state.results.incidents.length > 0 && <Grid item><h2>Intsidendid</h2></Grid>)}
            {(this.state.results.incidents.length > 0 && <Grid item style={{width: '100%'}}><IncidentSearchTable classes={classes} incidents={this.state.results.incidents} /></Grid>)}
            {(this.state.results.sessions.length > 0 && <Grid item><h2>Sessioonid</h2></Grid>)}
            {(this.state.results.sessions.length > 0 && <Grid item style={{width: '100%'}}><SessionSearchTable classes={classes} sessions={this.state.results.sessions} /></Grid>)}
          </Grid>
        </Paper>

      </Layout>
    );
  }
}

export default withRoot(withStyles(styles)(Overview));
